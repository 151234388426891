import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPepperHot, FaLeaf, FaUtensils, FaUserCircle, FaStar, FaCheck, FaShoppingCart,FaHeart,FaClock,FaArrowRight, FaPhoneAlt } from 'react-icons/fa';
import { GiNoodles, GiCook } from 'react-icons/gi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useInView } from 'react-intersection-observer';

import 'swiper/swiper-bundle.css';
import CountUp from 'react-countup';

// Import images
import chooseMealIcon from '../images/choose-meal-icon.png';
import placeOrderIcon from '../images/place-order-icon.png';
import swiftDeliveryIcon from '../images/swift-delivery-icon.png';
import heroImage from '../images/hero-image.jpg';
import chickenBiryaniImg from '../images/chicken-biryani.jpg';
import hyderabadiBiryaniImg from '../images/hyderabadi-biryani.jpg';
import southIndianImg from '../images/south-indian.jpg';
import vegBiryaniImg from '../images/veg-biryani.jpg';
import kitchenImage1 from '../images/kitchen1.jpg';
import kitchenImage2 from '../images/kitchen2.jpg';
import kitchenImage3 from '../images/kitchen3.jpg';
import biryaniBlissVideo from '../images/biryanibliss.mp4';
import dragonWokVideo from '../images/aaq.mp4';
import dosaDelightVideo from '../images/aaws.mp4';
import SubscriptionPlans from '../components/SubscriptionPlans';
import Hero from '../components/Hero';
import TrendingDishes from '../components/TrendingDishes';
import FAQ from '../components/FAQ';
import Testimonials from '../components/Testimonials';
import HowItWorks from '../components/HowItWorks';

// Updated menu items from pamphlet
const menuItems = [
  { category: 'Chat & Snacks', items: [
    { name: 'Chola Chat', price: { half: '₹60', full: '₹100' }},
    { name: 'Chola Puri', price: { half: '₹50', full: '₹90' }}
  ]},
  { category: 'Chinese', items: [
    { name: 'Veg Chowmin', price: { half: '₹70', full: '₹110' }},
    { name: 'Paneer Chowmin', price: { half: '₹60', full: '₹100' }},
    { name: 'Paneer Chilly', price: { half: '₹50', full: '₹80' }},
    { name: 'Soya Chilly', price: { half: '₹50', full: '₹80' }},
    { name: 'Munchurian', price: { half: '₹65', full: '₹100' }}
  ]},
  { category: 'Chicken', items: [
    { name: 'Chicken Chilly Bone', price: { half: '₹60', full: '₹120' }},
    { name: 'Chicken Chilly Boneless', price: { half: '₹80', full: '₹140' }}
  ]},
  { category: 'South Indian', items: [
    { name: 'Ghee Podi Masala Idli', price: { half: '₹40', full: '₹80' }},
    { name: 'Idli', price: { half: '₹40', full: '₹70' }}
  ]}
];

// Updated weekly menu from pamphlet
const weeklyMenu = {
  lunch: {
    mon: 'Rice, Dal, Aloo Gobhi, Pickle, 1pc Rasgulla',
    tue: 'Rice, Dal/Kadhi, Aloo Patal, Pyaaj Pakoda, 1pc Gulab Jamun',
    wed: 'Rice, Dal, Egg/Chicken Curry (1pc/2pc) / Matar Paneer, Salad, Kheer',
    thu: 'Veg Biriyani, Raita, Aachar, 1pc Chamcham',
    fri: 'Rice, Dal, Matar Paneer/Fish/Chicken, 1pc Rasgulla',
    sat: 'Rice, Seasonal Green Veg/Aloo Chana, Aachar, 1pc Gulab Jamun',
    sun: 'Jeera Rice, Matar Paneer/Chicken, Dal, Special Mithai'
  },
  dinner: {
    mon: 'Puri (4pc), Chola, Aachar',
    tue: 'Aloo/Sattu Paratha (4pc), Pickles',
    wed: 'Roti (6pc), Seasonal Green Veg',
    thu: 'Puri (6pc), Aloo Dum',
    fri: 'Roti (6pc), Mix Veg, Aloo Bhujiya',
    sat: 'Munchurian/Soya/Paneer Chilli',
    sun: 'Roti (6pc), Chola, Sawai'
  },
  breakfast: {
    mon: 'Puri Bhaji (4pc), Mint Chutney',
    tue: 'Aloo/Dal/Sattu Puri (3pc)',
    wed: 'Paratha Bhujiya (2pc), Curd',
    thu: 'Bread Sandwich',
    fri: 'Roti Bhujiya (3pc), Pickle',
    sat: 'Sattu/Aloo Paratha (2pc), Chutney',
    sun: 'Chowmin/Idli (3pc), Sauce/Sambhar'
  }
};

const kitchens = [
  { 
    name: 'Sai Green Kitchen', 
    image: kitchenImage1,
    cuisineType: 'North Indian',
    rating: 4.8,
    specialty: 'Home Style Meals', 
    offer: '20% off on first subscription',
    icon: GiCook,
    video: biryaniBlissVideo
  },
  { 
    name: 'Chinese Corner', 
    image: kitchenImage2,
    cuisineType: 'Indo-Chinese',
    rating: 4.6,
    specialty: 'Authentic Chinese', 
    offer: 'Free delivery on orders above ₹200',
    icon: FaPepperHot,
    video: dragonWokVideo
  },
  { 
    name: 'South Indian Express', 
    image: kitchenImage3,
    cuisineType: 'South Indian',
    rating: 4.7,
    specialty: 'Fresh Idli & Dosa', 
    offer: 'Special breakfast combos',
    icon: GiNoodles,
    video: dosaDelightVideo
  }
];




const HomePage = () => {
  const [activeKitchen, setActiveKitchen] = useState(0);
  const [liveOrders, setLiveOrders] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activeWeekDay, setActiveWeekDay] = useState('mon');
  const [trendingDishes, setTrendingDishes] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveKitchen((prev) => (prev + 1) % kitchens.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const generateLiveOrders = () => {
      const newOrders = Array.from({ length: 5 }, () => ({
        id: Math.random().toString(36).substr(2, 9),
        dish: `${['Veg', 'Chicken', 'Paneer', 'Mutton'][Math.floor(Math.random() * 4)]} ${['Biryani', 'Curry', 'Thali', 'Noodles'][Math.floor(Math.random() * 4)]}`,
        status: ['Preparing', 'Cooking', 'Packaging', 'Out for Delivery'][Math.floor(Math.random() * 4)],
        estimatedTime: Math.floor(Math.random() * 30) + 15,
        kitchen: kitchens[Math.floor(Math.random() * kitchens.length)].name,
        customerRating: (Math.random() * 2 + 3).toFixed(1),
        temperature: Math.floor(Math.random() * 20) + 60,
        quantity: Math.floor(Math.random() * 3) + 1,
      }));
      setLiveOrders(newOrders);
    };

    const generateTrendingDishes = () => {
      const dishes = [
        { name: 'Hyderabadi Biryani', price: '₹250', rating: 4.8, orders: 120, image: hyderabadiBiryaniImg },
        { name: 'Butter Chicken', price: '₹280', rating: 4.7, orders: 98, image: chickenBiryaniImg },
        { name: 'Masala Dosa', price: '₹120', rating: 4.6, orders: 85, image: southIndianImg },
        { name: 'Paneer Tikka', price: '₹220', rating: 4.5, orders: 76, image: vegBiryaniImg },
        { name: 'Chilli Chicken', price: '₹260', rating: 4.7, orders: 92, image: chickenBiryaniImg },
      ];
      setTrendingDishes(dishes);
    };

    generateLiveOrders();
    generateTrendingDishes();

    const interval = setInterval(() => {
      generateLiveOrders();
      generateTrendingDishes();
    }, 10000);

    return () => clearInterval(interval);
  }, []);



 



  const KitchenShowcase = ({ activeKitchen, setActiveKitchen }) => (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
          Our Kitchens in Action
        </h2>
        <div className="relative h-96 bg-gray-200 rounded-lg overflow-hidden">
          {/* Video Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-70 z-10" />
          
          <div className="absolute top-4 left-4 flex items-center z-20">
            <span className="flex h-3 w-3 relative">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
            <span className="ml-2 text-sm font-semibold text-white">LIVE</span>
          </div>

          <AnimatePresence>
            <motion.div
              key={activeKitchen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0 flex items-center justify-center"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute inset-0 w-full h-full object-cover"
              >
                <source src={kitchens[activeKitchen].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="relative text-center text-white z-20 px-4">
                <div className="text-6xl mb-4">
                  {React.createElement(kitchens[activeKitchen].icon)}
                </div>
                <h3 className="text-3xl font-bold mb-3">{kitchens[activeKitchen].name}</h3>
                <p className="text-xl mb-3">{kitchens[activeKitchen].specialty}</p>
                <div className="flex items-center justify-center mb-4">
                  <div className="flex items-center bg-white bg-opacity-20 px-4 py-2 rounded-full">
                    <span className="text-yellow-400 mr-2">
                      {[...Array(5)].map((_, i) => (
                        <FaStar
                          key={i}
                          className={`inline ${i < kitchens[activeKitchen].rating ? 'text-yellow-400' : 'text-gray-400'}`}
                        />
                      ))}
                    </span>
                    <span>{kitchens[activeKitchen].rating} Rating</span>
                  </div>
                </div>
                <p className="text-green-400 font-semibold mb-4">{kitchens[activeKitchen].offer}</p>
                <div className="flex justify-center gap-4">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300 flex items-center gap-2"
                  >
                    <FaUtensils /> Explore Menu
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white text-green-600 px-6 py-2 rounded-full hover:bg-green-50 transition duration-300 flex items-center gap-2"
                  >
                    <FaPhoneAlt /> Contact Kitchen
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="mt-8 flex justify-center space-x-4">
          {kitchens.map((kitchen, index) => (
            <motion.button
              key={kitchen.name}
              className={`px-4 py-2 rounded-full flex items-center gap-2 ${
                index === activeKitchen 
                  ? 'bg-green-500 text-white' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
              onClick={() => setActiveKitchen(index)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {React.createElement(kitchen.icon, { className: "text-sm" })}
              <span className="text-sm font-medium">{kitchen.name}</span>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white">
      <Hero />
      <HowItWorks />
      <KitchenShowcase activeKitchen={activeKitchen} setActiveKitchen={setActiveKitchen} />
      {/* <TrendingDishes  dishes={menuItems}/> */}
      <SubscriptionPlans />
      <Testimonials />
      <FAQ />
    </div>
  );
};

export default HomePage;
