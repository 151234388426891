import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaFileInvoice, FaCreditCard, FaCalendarAlt, FaLock, FaShieldAlt, FaShoppingBag, FaMoneyBillWave, FaClock, FaCheck, FaDownload, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

const DefaultComponent = () => {
  const navigate = useNavigate();
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen flex items-center justify-center p-4 "
    >
      <div className="text-center space-y-6 max-w-md">
        <div className="w-24 h-24 mx-auto bg-gray-50 rounded-full flex items-center justify-center">
          <FaShoppingBag className="w-12 h-12 text-gray-400" />
        </div>
        <h2 className="text-2xl font-bold text-gray-900">No Active Order</h2>
        <p className="text-gray-600">
          Please configure your tiffin service preferences before proceeding to payment.
        </p>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => navigate('/tiffin-booking')}
          className="inline-flex items-center px-6 py-3 bg-green-500 text-white 
            rounded-xl shadow-lg hover:bg-green-600 transition-colors"
        >
          Configure Tiffin Service
        </motion.button>
      </div>
    </motion.div>
  );
};

const Invoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderDetails = location.state?.orderDetails;
  const [paymentOption, setPaymentOption] = useState(null);

  const onPaymentSelect = (selectedOption) => {
    if (!selectedOption) return;
    // Navigate to payment processing with selected option
    navigate('/payment', {
      state: {
        paymentOption: selectedOption,
        orderDetails: orderDetails
      }
    });
  };

  if (!orderDetails) {
    return <DefaultComponent />;
  }

  const paymentOptions = [
    {
      id: 'full',
      title: 'Pay Full Amount',
      description: 'Pay the entire amount now and get started',
      amount: orderDetails.totalAmount,
      badge: '5% Extra Discount',
      icon: FaMoneyBillWave,
      benefits: [
        'Instant activation of service',
        'Additional 5% discount applied',
        'No future payment hassles'
      ]
    },
    {
      id: 'monthly',
      title: 'Monthly Payment',
      description: 'Split your payment into monthly installments',
      amount: orderDetails.monthlyAmount,
      badge: 'Most Popular',
      icon: FaCalendarAlt,
      benefits: [
        'Flexible payment schedule',
        'No additional charges',
        'Easy on your budget'
      ]
    },
    {
      id: 'later',
      title: 'Pay Later',
      description: 'Start now, pay within 15 days',
      amount: orderDetails.totalAmount,
      badge: 'No Extra Charges',
      icon: FaClock,
      benefits: [
        'Start immediately',
        '15-day payment window',
        'Zero additional fees'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 mt-24">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto space-y-8 px-4"
      >
        {/* Invoice Header Card */}
        <motion.div 
          className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="bg-green-50 p-3 rounded-xl">
                <FaFileInvoice className="w-8 h-8 text-green-500" />
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900">Invoice</h2>
                <p className="text-sm text-gray-500">Order #{orderDetails.orderId}</p>
              </div>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">Generated on</p>
              <p className="font-medium text-gray-900">{new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}</p>
            </div>
          </div>

          {/* Order Summary */}
          <div className="mt-8 border-t border-gray-100 pt-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Order Summary</h3>
            <div className="space-y-4">
              <div className="flex justify-between items-center py-3 border-b border-gray-100">
                <div>
                  <h4 className="font-medium text-gray-900">{orderDetails.plan.name}</h4>
                  <p className="text-sm text-gray-500">{orderDetails.plan.meals}</p>
                </div>
                <span className="text-gray-900 font-medium">₹{orderDetails.plan.price.toLocaleString()}</span>
              </div>
              <div className="flex justify-between items-center py-3 border-b border-gray-100">
                <div>
                  <h4 className="font-medium text-gray-900">Duration</h4>
                  <p className="text-sm text-gray-500">{orderDetails.duration.label}</p>
                </div>
                <span className="text-green-500 font-medium">-{orderDetails.duration.discount}%</span>
              </div>
              {orderDetails.quantity > 1 && (
                <div className="flex justify-between items-center py-3 border-b border-gray-100">
                  <div>
                    <h4 className="font-medium text-gray-900">Quantity</h4>
                    <p className="text-sm text-gray-500">{orderDetails.quantity} people</p>
                  </div>
                  <span className="text-gray-900">×{orderDetails.quantity}</span>
                </div>
              )}
              {orderDetails.savings > 0 && (
                <div className="flex justify-between items-center py-3 border-b border-gray-100">
                  <span className="font-medium text-green-500">Total Savings</span>
                  <span className="font-bold text-green-500">₹{orderDetails.savings.toLocaleString()}</span>
                </div>
              )}
            </div>
          </div>
        </motion.div>

        {/* Payment Options */}
        <div className="space-y-6">
          <h3 className="text-xl font-bold text-gray-900">Choose Payment Option</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {paymentOptions.map((option) => (
              <motion.div
                key={option.id}
                whileHover={{ scale: 1.02, translateY: -5 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setPaymentOption(option.id)}
                className={`cursor-pointer rounded-2xl border-2 transition-all duration-200
                  ${paymentOption === option.id 
                    ? 'border-green-500 bg-green-50 shadow-lg' 
                    : 'border-gray-200 hover:border-gray-300 hover:shadow-md'}`}
              >
                <div className="p-6">
                  {option.badge && (
                    <span className="inline-block px-3 py-1 rounded-full bg-green-100 
                      text-green-600 text-xs font-medium mb-4">
                      {option.badge}
                    </span>
                  )}
                  <div className="flex items-center space-x-3 mb-4">
                    <option.icon className={`w-6 h-6 ${
                      paymentOption === option.id ? 'text-green-500' : 'text-gray-400'
                    }`} />
                    <h4 className="font-semibold text-gray-900">{option.title}</h4>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">{option.description}</p>
                  <div className="text-2xl font-bold text-gray-900 mb-4">
                    ₹{option.amount.toLocaleString()}
                    {option.id === 'monthly' && (
                      <span className="text-sm font-normal text-gray-500">/month</span>
                    )}
                  </div>
                  <div className="space-y-2">
                    {option.benefits.map((benefit, index) => (
                      <div key={index} className="flex items-center space-x-2 text-sm text-gray-600">
                        <FaCheck className="w-4 h-4 text-green-500" />
                        <span>{benefit}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between items-center space-x-4 pt-6">
          <div className="flex items-center space-x-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => navigate('/tiffin-booking')}
              className="flex items-center space-x-2 px-6 py-3 text-gray-600 
                hover:text-gray-900 border-2 border-gray-200 rounded-xl 
                hover:border-gray-300 transition-all duration-200"
            >
              <FaArrowLeft className="w-4 h-4" />
              <span>Change Plan</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="flex items-center space-x-2 px-6 py-3 text-gray-600 
                hover:text-gray-900 border-2 border-gray-200 rounded-xl 
                hover:border-gray-300 transition-all duration-200"
            >
              <FaDownload className="w-4 h-4" />
              <span>Download Invoice</span>
            </motion.button>
          </div>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => onPaymentSelect(paymentOption)}
            disabled={!paymentOption}
            className="flex items-center space-x-2 px-8 py-3 bg-gradient-to-r 
              from-green-500 to-green-600 text-white font-medium rounded-xl 
              shadow-lg disabled:opacity-50 disabled:cursor-not-allowed 
              hover:shadow-xl transition-all duration-200"
          >
            <span>Continue to Payment</span>
            <FaArrowRight className="w-4 h-4" />
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default Invoice; 