import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaCrown, FaUtensils, FaCoffee, FaRegClock } from 'react-icons/fa';

// Create a custom Badge component since @/components/ui/badge is not available
const Badge = ({ children, className = '' }) => (
  <span className={`px-2 py-1 text-sm font-semibold rounded-full ${className}`}>
    {children}
  </span>
);

// Move plans data inside component to avoid undefined error
export const SubscriptionPlans = () => {
  const [hoveredPlan, setHoveredPlan] = useState(null);
  const navigate = useNavigate();

  const subscriptionPlans = [
    {
      name: 'Basic',
      price: '₹1,700',
      originalPrice: '₹2,299',
      meals: '7 meals/week',
      description: 'Perfect for those who need a hearty lunch.',
      features: [
        'One meal per day',
        'Free delivery within 5km',
        'Weekly menu rotation',
        'Quality home-cooked food'
      ]
    },
    {
      name: 'Standard',
      price: '₹3,299',
      originalPrice: '₹3,999',
      meals: '14 meals/week',
      description: 'Ideal for busy professionals.',
      features: [
        'Two meals per day',
        'Free delivery within 5km',
        'Weekly menu rotation',
        'Flexible meal timing',
        'Quality home-cooked food'
      ]
    },
    {
      name: 'Premium',
      price: '₹4,099',
      originalPrice: '₹5,499',
      meals: '21 meals/week',
      description: 'Complete nutrition for your entire day.',
      features: [
        'Three meals per day',
        'Free delivery within 5km',
        'Weekly menu rotation',
        'All meals covered',
        'Premium menu options',
        'Priority delivery'
      ]
    },
    {
      name: 'Nashta',
      price: '₹1,199',
      originalPrice: '₹1,599',
      meals: '7 breakfast/week',
      description: 'Start your day right with our breakfast plan.',
      features: [
        'Daily breakfast delivery',
        'Free delivery within 5km',
        'Fresh morning delivery',
        'Variety of breakfast options'
      ]
    }
  ];

  const getPlanIcon = (planName) => {
    switch (planName.toLowerCase()) {
      case 'premium':
        return <FaCrown className="w-8 h-8 text-yellow-500" />;
      case 'standard':
        return <FaUtensils className="w-8 h-8 text-blue-500" />;
      case 'basic':
        return <FaRegClock className="w-8 h-8 text-green-500" />;
      case 'nashta':
        return <FaCoffee className="w-8 h-8 text-orange-500" />;
      default:
        return null;
    }
  };

  const handleSubscribe = (plan) => {
    navigate('/meal-plans/subscribe', { state: { selectedPlan: plan } });
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
            Choose Your Tiffin Plan
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Flexible meal plans tailored to your needs with premium quality food and reliable delivery
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {subscriptionPlans.map((plan) => (
            <motion.div
              key={plan.name}
              onHoverStart={() => setHoveredPlan(plan.name)}
              onHoverEnd={() => setHoveredPlan(null)}
              className={`relative ${plan.name === 'Premium' ? 'lg:-mt-8' : ''}`}
            >
              <motion.div
                animate={{
                  scale: hoveredPlan === plan.name ? 1.05 : 1,
                  boxShadow: hoveredPlan === plan.name 
                    ? '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
                    : '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
                className={`relative bg-white rounded-2xl overflow-hidden ${
                  plan.name === 'Premium' 
                    ? 'border-2 border-yellow-400 ring-2 ring-yellow-400 ring-opacity-50'
                    : 'border border-gray-200'
                }`}
              >
                {plan.name === 'Premium' && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2">
                    <Badge className="bg-yellow-400 text-yellow-900">Most Popular</Badge>
                  </div>
                )}

                <div className="p-8">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-3">
                      {getPlanIcon(plan.name)}
                      <h3 className="font-bold text-2xl text-gray-900">{plan.name}</h3>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="flex items-baseline mb-2">
                      <span className="text-4xl font-extrabold text-gray-900">{plan.price}</span>
                      <span className="text-gray-500 line-through ml-2">{plan.originalPrice}</span>
                      <span className="ml-2 text-green-600 font-medium">
                        {Math.round((1 - parseInt(plan.price.replace('₹', '').replace(',', '')) / 
                                   parseInt(plan.originalPrice.replace('₹', '').replace(',', ''))) * 100)}% off
                      </span>
                    </div>
                    <p className="text-gray-600">{plan.meals}</p>
                  </div>

                  <div className="border-t border-gray-100 pt-6 mb-8">
                    <p className="text-gray-700 mb-6">{plan.description}</p>
                    <ul className="space-y-4">
                      {plan.features.map((feature) => (
                        <motion.li 
                          key={feature}
                          className="flex items-start"
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <span className="flex-shrink-0 inline-flex items-center justify-center h-6 w-6 rounded-full bg-green-100">
                            <FaCheck className="h-4 w-4 text-green-600" />
                          </span>
                          <span className="ml-3 text-gray-600">{feature}</span>
                        </motion.li>
                      ))}
                    </ul>
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleSubscribe(plan)}
                    className={`w-full py-4 px-6 rounded-xl font-semibold text-lg transition-all duration-300 ${
                      plan.name === 'Premium'
                        ? 'bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 text-white shadow-xl hover:shadow-2xl'
                        : 'bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white'
                    }`}
                  >
                    Get Started
                  </motion.button>

                  {plan.name === 'Premium' && (
                    <p className="text-center text-sm text-gray-500 mt-4">
                      30-day money-back guarantee
                    </p>
                  )}
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;