import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';

import { 
  FaUtensils, FaClock, FaCalendarAlt, FaUsers, 
  FaCrown, FaCoffee, FaRegClock, FaCheck,
  FaArrowRight, FaPercent, FaCalculator, FaInfoCircle, 
  FaShieldAlt, FaMoneyBillWave, FaTruck, FaLeaf, FaDrumstickBite, FaUser, FaMinus, FaPlus, FaTag, FaQrcode, FaCreditCard, FaUniversity, FaWallet, FaLock, FaShield
} from 'react-icons/fa';
import { toast } from 'react-hot-toast';



const subscriptionPlans = [
  {
    id: 'nashta',
    name: 'Nashta',
    price: 1199,
    originalPrice: 1599,
    meals: '7 breakfast/week',
    description: 'Start your day right with our breakfast plan.',
    icon: FaCoffee,
    iconColor: 'text-orange-500',
    features: [
      'Daily breakfast delivery',
      'Free delivery within 5km',
      'Fresh morning delivery',
      'Variety of breakfast options'
    ]
  },
  {
    id: 'basic',
    name: 'Basic',
    price: 1700,
    originalPrice: 2299,
    meals: '7 meals/week',
    description: 'Perfect for those who need a hearty lunch.',
    icon: FaRegClock,
    iconColor: 'text-green-500',
    features: [
      'One meal per day',
      'Free delivery within 5km',
      'Weekly menu rotation',
      'Quality home-cooked food'
    ]
  },
  {
    id: 'standard',
    name: 'Standard',
    price: 3299,
    originalPrice: 3999,
    meals: '14 meals/week',
    description: 'Ideal for busy professionals.',
    icon: FaUtensils,
    iconColor: 'text-blue-500',
    features: [
      'Two meals per day',
      'Free delivery within 5km',
      'Weekly menu rotation',
      'Flexible meal timing',
      'Quality home-cooked food'
    ]
  },
  {
    id: 'premium',
    name: 'Premium',
    price: 4099,
    originalPrice: 5499,
    meals: '21 meals/week',
    description: 'Complete nutrition for your entire day.',
    icon: FaCrown,
    iconColor: 'text-yellow-500',
    features: [
      'Three meals per day',
      'Free delivery within 5km',
      'Weekly menu rotation',
      'All meals covered',
      'Premium menu options',
      'Priority delivery'
    ]
  }
];

const durationOptions = [
  { months: 1, discount: 0, label: '1 Month' },
  { months: 3, discount: 5, label: '3 Months' },
  { months: 6, discount: 10, label: '6 Months' },
  { months: 12, discount: 15, label: '12 Months' }
];

const dietaryPreferences = [
  {
    id: 'veg',
    name: 'Vegetarian',
    icon: FaLeaf,
    description: 'Plant-based meals with dairy options',
    iconColor: 'text-green-500',
    benefits: [
      'Fresh seasonal vegetables',
      'High-protein meat alternatives',
      'Balanced nutritional content',
      'Traditional Indian recipes'
    ]
  },
  {
    id: 'nonveg',
    name: 'Non-Vegetarian', 
    icon: FaDrumstickBite,
    description: 'Including chicken, fish and meat dishes',
    iconColor: 'text-red-500',
    benefits: [
      'Premium quality meats',
      'Protein-rich meals',
      'Varied weekly menu',
      'Traditional recipes'
    ]
  }
];

const PlanCard = ({ plan, isSelected, onSelect }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      layout
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onClick={() => onSelect(plan)}
      animate={{ 
        scale: isSelected ? 1.02 : 1,
        y: isSelected ? -8 : 0
      }}
      className={`relative cursor-pointer rounded-2xl overflow-hidden transition-shadow
        ${isSelected 
          ? 'ring-2 ring-green-500 shadow-xl' 
          : 'hover:shadow-lg border border-gray-200'
        }`}
    >
      <div className="p-6 bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <div className={`p-3 rounded-xl ${isSelected ? 'bg-green-50' : 'bg-gray-50'}`}>
              <plan.icon className={`w-6 h-6 ${plan.iconColor}`} />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900">{plan.name}</h3>
              <p className="text-sm text-gray-500">{plan.meals}</p>
            </div>
          </div>
          {isSelected && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-6 h-6 rounded-full bg-green-500 flex items-center justify-center"
            >
              <FaCheck className="text-white w-4 h-4" />
            </motion.div>
          )}
        </div>

        <div className="mb-4">
          <div className="flex items-baseline space-x-2">
            <span className="text-3xl font-bold text-gray-900">₹{plan.price.toLocaleString()}</span>
            <span className="text-gray-500 line-through text-sm">
              ₹{plan.originalPrice.toLocaleString()}
            </span>
            <span className="text-green-500 text-sm font-medium">
              {Math.round((1 - plan.price / plan.originalPrice) * 100)}% off
            </span>
          </div>
        </div>

        <AnimatePresence>
          {(isSelected || isHovered) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-2 mt-4"
            >
              {plan.features.map((feature, index) => (
                <motion.div
                  key={feature}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-center space-x-2 text-sm text-gray-600"
                >
                  <FaCheck className="text-green-500 flex-shrink-0" />
                  <span>{feature}</span>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const DurationCard = ({ option, isSelected, onSelect, basePrice }) => {
  const monthlyPrice = basePrice * (1 - option.discount / 100);
  const totalPrice = monthlyPrice * option.months;

  return (
    <motion.div
      layout
      onClick={() => onSelect(option)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`cursor-pointer p-6 rounded-xl bg-white transition-all
        ${isSelected 
          ? 'ring-2 ring-green-500 shadow-xl' 
          : 'hover:shadow-lg border border-gray-200'
        }`}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className={`p-3 rounded-xl ${isSelected ? 'bg-green-50' : 'bg-gray-50'}`}>
            <FaClock className="w-6 h-6 text-green-500" />
          </div>
          <h3 className="text-xl font-bold text-gray-900">{option.label}</h3>
        </div>
        {option.discount > 0 && (
          <div className="px-3 py-1 rounded-full bg-green-50 text-green-600 text-sm font-medium">
            {option.discount}% off
          </div>
        )}
      </div>

      <div className="space-y-2">
        <div className="flex items-baseline space-x-2">
          <span className="text-2xl font-bold text-gray-900">
            ₹{monthlyPrice.toLocaleString()}
          </span>
          <span className="text-gray-500">/month</span>
        </div>
        <div className="text-sm text-gray-600">
          Total: ₹{totalPrice.toLocaleString()} for {option.months} months
        </div>
      </div>
    </motion.div>
  );
};

const QuantitySelector = ({ value, onChange }) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      {/* Visual People Counter */}
      <div className="flex flex-col items-center space-y-4">
        <div className="relative h-24 w-24 flex items-center justify-center">
          <motion.div
            animate={{ 
              scale: value === 0 ? 0.8 : 1,
              opacity: value === 0 ? 0.5 : 1 
            }}
            className="absolute inset-0"
          >
            <FaUsers className="w-full h-full text-green-500/20" />
          </motion.div>
          <span className="text-4xl font-bold text-gray-900">{value}</span>
        </div>
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center"
        >
          <h3 className="text-xl font-semibold text-gray-900">
            {value === 0 ? 'Select Number of People' : `${value} ${value === 1 ? 'Person' : 'People'}`}
          </h3>
          <p className="text-sm text-gray-500 mt-1">Choose how many tiffins you need</p>
        </motion.div>
      </div>

      {/* Quantity Controls */}
      <div className="flex items-center justify-center space-x-6">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onChange(Math.max(0, value - 1))}
          className={`w-12 h-12 rounded-full flex items-center justify-center
            ${value === 0 
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-green-100 text-green-600 hover:bg-green-200'
            } transition-colors`}
          disabled={value === 0}
        >
          <FaMinus className="w-4 h-4" />
        </motion.button>

        <div className="w-20">
          <input
            type="number"
            min="0"
            max="20"
            value={value}
            onChange={(e) => onChange(Math.min(20, Math.max(0, parseInt(e.target.value) || 0)))}
            className="w-full text-center text-2xl font-semibold py-2 rounded-lg
              border-2 border-gray-200 focus:border-green-500 focus:ring-2 
              focus:ring-green-200 outline-none transition-all"
          />
        </div>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onChange(Math.min(20, value + 1))}
          className="w-12 h-12 rounded-full bg-green-100 text-green-600 
            hover:bg-green-200 flex items-center justify-center transition-colors"
          disabled={value >= 20}
        >
          <FaPlus className="w-4 h-4" />
        </motion.button>
      </div>

      {/* Quick Selection Buttons */}
      <div className="flex flex-wrap justify-center gap-3">
        {[2, 4, 6, 8].map((num) => (
          <motion.button
            key={num}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => onChange(num)}
            className={`px-4 py-2 rounded-full text-sm font-medium 
              ${value === num 
                ? 'bg-green-500 text-white' 
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              } transition-all`}
          >
            {num} People
          </motion.button>
        ))}
      </div>

      {/* Information Section */}
      <div className="text-sm text-gray-500 space-y-2">
        <div className="flex items-center justify-center space-x-2">
          <FaInfoCircle className="text-green-500" />
          <span>Maximum 20 people per order</span>
        </div>
        {value >= 10 && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center justify-center space-x-2 text-green-600"
          >
            <FaTag />
            <span>Bulk order discount available!</span>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const DatePicker = ({ selectedDate, onDateSelect, minDate }) => {
  const today = new Date();
  const availableDates = Array.from({ length: 30 }, (_, i) => {
    const date = new Date();
    date.setDate(today.getDate() + i + 1);
    return date;
  });

  return (
    <div className="grid grid-cols-4 gap-4">
      {availableDates.map((date) => {
        const isAvailable = date > minDate;
        const isSelected = selectedDate && date.toDateString() === selectedDate.toDateString();

        return (
          <motion.button
            key={date.toISOString()}
            whileHover={{ scale: isAvailable ? 1.05 : 1 }}
            whileTap={{ scale: isAvailable ? 0.95 : 1 }}
            onClick={() => isAvailable && onDateSelect(date)}
            className={`p-4 rounded-xl text-center transition-colors
              ${isSelected 
                ? 'bg-green-500 text-white'
                : isAvailable
                  ? 'bg-white hover:bg-gray-50 text-gray-900'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              } border ${isSelected ? 'border-green-500' : 'border-gray-200'}`}
          >
            <div className="text-sm font-medium">
              {date.toLocaleDateString('en-US', { weekday: 'short' })}
            </div>
            <div className="text-lg font-bold mt-1">
              {date.getDate()}
            </div>
          </motion.button>
        );
      })}
    </div>
  );
};

const PaymentSection = ({ totalAmount, onPaymentComplete }) => {
  const [paymentMethod, setPaymentMethod] = useState('upi');
  const [loading, setLoading] = useState(false);
  const [upiId, setUpiId] = useState('');
  
  const paymentMethods = [
    { id: 'upi', name: 'UPI Payment', icon: FaQrcode },
    { id: 'card', name: 'Credit/Debit Card', icon: FaCreditCard },
    { id: 'netbanking', name: 'Net Banking', icon: FaUniversity },
    { id: 'wallet', name: 'Digital Wallet', icon: FaWallet }
  ];

  const handlePayment = async () => {
    setLoading(true);
    try {
      // Simulated payment processing
      await new Promise(resolve => setTimeout(resolve, 1500));
      onPaymentComplete();
      toast.success('Payment successful!');
    } catch (error) {
      toast.error('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      {/* Payment Method Selection */}
      <div className="grid grid-cols-2 gap-4">
        {paymentMethods.map(method => (
          <motion.button
            key={method.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setPaymentMethod(method.id)}
            className={`p-4 rounded-xl border-2 transition-all
              ${paymentMethod === method.id 
                ? 'border-green-500 bg-green-50' 
                : 'border-gray-200 hover:border-gray-300'}`}
          >
            <method.icon className={`w-6 h-6 mb-2 ${
              paymentMethod === method.id ? 'text-green-500' : 'text-gray-400'
            }`} />
            <span className="text-sm font-medium">{method.name}</span>
          </motion.button>
        ))}
      </div>

      {/* Payment Details Form */}
      <AnimatePresence mode="wait">
        {paymentMethod === 'upi' && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-4"
          >
            <div className="relative">
              <input
                type="text"
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
                placeholder="Enter UPI ID"
                className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 
                  focus:border-green-500 focus:ring-2 focus:ring-green-200 
                  transition-all"
              />
              <FaQrcode 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 
                  text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => {/* QR code scanner logic */}}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Payment Button */}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        disabled={loading}
        onClick={handlePayment}
        className="w-full py-4 bg-gradient-to-r from-green-500 to-green-600 
          text-white font-bold rounded-xl shadow-lg relative overflow-hidden"
      >
        <motion.div
          className="absolute inset-0 bg-white"
          initial={false}
          animate={{
            x: loading ? '100%' : '-100%',
            transition: { duration: 1, repeat: Infinity }
          }}
          style={{ opacity: 0.2 }}
        />
        <span className="relative z-10 flex items-center justify-center space-x-2">
          {loading ? (
            <>
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
              />
              <span>Processing...</span>
            </>
          ) : (
            <>
              <span>Pay ₹{totalAmount.toLocaleString()}</span>
              <FaLock className="w-4 h-4" />
            </>
          )}
        </span>
      </motion.button>

      {/* Security Badges */}
      <div className="flex items-center justify-center space-x-4 text-gray-400">
        <FaLock className="w-4 h-4" />
        <span className="text-sm">256-bit encryption</span>
        <FaShieldAlt className="w-4 h-4" />
        <span className="text-sm">Secure payment</span>
      </div>
    </motion.div>
  );
};


const BillingPanel = ({ 
  selectedPlan, 
  selectedDuration, 
  quantity,
  startDate,
  className = '',
  onProceedToPayment 
}) => {
  const navigate = useNavigate();
  const basePrice = selectedPlan?.price || 0;
  const months = selectedDuration?.months || 1;
  const discount = selectedDuration?.discount || 0;
  const monthlyPrice = basePrice * (1 - discount / 100);
  const totalPrice = monthlyPrice * months * quantity;
  const savings = (basePrice * months * quantity) - totalPrice;

  // Check if all required fields are filled
  const isReadyToPay = selectedPlan && selectedDuration && quantity > 0;

  return (
    <motion.div
      layout
      className={`bg-white rounded-2xl shadow-lg p-6 ${className}`}
    >
      <h3 className="text-xl font-bold text-gray-900 mb-6">Order Summary</h3>

      <div className="space-y-4">
        {selectedPlan && (
          <div className="flex justify-between items-center pb-4 border-b">
            <div>
              <h4 className="font-medium text-gray-900">{selectedPlan.name} Plan</h4>
              <p className="text-sm text-gray-500">{selectedPlan.meals}</p>
            </div>
            <span className="text-lg font-bold text-gray-900">
              ₹{basePrice.toLocaleString()}/month
            </span>
          </div>
        )}

        {selectedDuration && (
          <div className="flex justify-between items-center pb-4 border-b">
            <div>
              <h4 className="font-medium text-gray-900">Duration</h4>
              <p className="text-sm text-gray-500">{selectedDuration.label}</p>
            </div>
            {selectedDuration.discount > 0 && (
              <span className="text-green-500 font-medium">
                -{selectedDuration.discount}%
              </span>
            )}
          </div>
        )}

        {quantity > 1 && (
          <div className="flex justify-between items-center pb-4 border-b">
            <div>
              <h4 className="font-medium text-gray-900">Quantity</h4>
              <p className="text-sm text-gray-500">{quantity} people</p>
            </div>
              <span className="text-gray-900">×{quantity}</span>
          </div>
        )}

        {savings > 0 && (
          <div className="flex justify-between items-center pb-4 border-b text-green-500">
            <span className="font-medium">Total Savings</span>
            <span className="font-bold">₹{savings.toLocaleString()}</span>
          </div>
        )}

        <div className="flex justify-between items-center pt-2 text-xl font-bold">
          <span>Total Amount</span>
          <span className="text-green-600">₹{totalPrice.toLocaleString()}</span>
        </div>

        {selectedDuration && (
          <div className="text-sm text-gray-500 text-center">
            <p>Note: This is a one-time payment for the selected plan and duration. No refunds will be issued for unused meals.</p>
          </div>
        )}

        {/* Show payment button only when all fields are filled */}
        <AnimatePresence>
          {isReadyToPay && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onProceedToPayment}
                className="w-full py-4 px-8 bg-gradient-to-r from-green-500 to-green-600 
                  text-white font-bold rounded-xl shadow-lg transform transition-all 
                  duration-300 hover:shadow-xl flex items-center justify-center space-x-2"
              >
                <span>Proceed to Payment</span>
                <FaArrowRight className="w-5 h-5" />
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Show helper text when fields are not complete */}
        <AnimatePresence>
          {!isReadyToPay && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center text-gray-500 text-sm p-4 bg-gray-50 rounded-lg"
            >
              <FaInfoCircle className="inline-block mr-2" />
              Complete your selection to proceed with payment
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const PreferenceCard = ({ preference, isSelected, onSelect }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      whileHover={{ scale: 1.02 }}
      onClick={() => {
        onSelect(preference);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
      }}
      className={`cursor-pointer rounded-2xl overflow-hidden transition-all
        ${isSelected 
          ? 'ring-2 ring-green-500 shadow-xl' 
          : 'hover:shadow-lg border border-gray-200'}`}
    >
      <div className="p-6 bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <div className={`p-3 rounded-xl ${isSelected ? 'bg-green-50' : 'bg-gray-50'}`}>
              <preference.icon className={`w-6 h-6 ${preference.iconColor}`} />
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-900">{preference.name}</h3>
              <p className="text-sm text-gray-500">{preference.description}</p>
            </div>
          </div>
        </div>

        <div className="space-y-3 mt-4">
          {preference.benefits.map((benefit, index) => (
            <motion.div
              key={benefit}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center space-x-2"
            >
              <FaCheck className={`${preference.iconColor} flex-shrink-0`} />
              <span className="text-sm text-gray-600">{benefit}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const TiffinBooking = () => {
  // State management
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [dietaryPreference, setDietaryPreference] = useState(null);

  // Calculate minimum start date (next day)
  const minStartDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  // Scroll to section when step changes
  useEffect(() => {
    const element = document.getElementById(`step-${currentStep}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentStep]);

  // Update steps array
  const steps = [
    { step: 1, label: 'Dietary Preference', icon: FaLeaf },
    { step: 2, label: 'Select Plan', icon: FaUtensils },
    { step: 3, label: 'Choose Duration', icon: FaClock },
    { step: 4, label: 'Set Quantity', icon: FaUsers },
    { step: 5, label: 'Pick Start Date', icon: FaCalendarAlt }
  ];

  // Filter plans based on dietary preference
  const filteredPlans = useMemo(() => {
    if (!dietaryPreference) return [];
    return subscriptionPlans.map(plan => ({
      ...plan,
      price: dietaryPreference.id === 'nonveg' ? plan.price * 1.2 : plan.price,
      originalPrice: dietaryPreference.id === 'nonveg' ? plan.originalPrice * 1.2 : plan.originalPrice
    }));
  }, [dietaryPreference]);

  const navigate = useNavigate();

  const handleProceedToPayment = () => {
    if (!selectedPlan || !selectedDuration || !quantity || !startDate) {
      toast.error('Please complete all selections');
      return;
    }

    const basePrice = selectedPlan.price;
    const months = selectedDuration.months;
    const discount = selectedDuration.discount;
    const monthlyPrice = basePrice * (1 - discount / 100);
    const totalPrice = monthlyPrice * months * quantity;

    const simplifiedPlan = {
      id: selectedPlan.id,
      name: selectedPlan.name,
      price: selectedPlan.price,
      meals: selectedPlan.meals,
      description: selectedPlan.description
    };

    navigate('/invoice', { 
      replace: true,
      state: {
        orderDetails: {
          orderId: `TIF${Date.now()}`,
          plan: simplifiedPlan,  // Use simplified plan object
          duration: {
            months: selectedDuration.months,
            label: selectedDuration.label,
            discount: selectedDuration.discount
          },
          quantity: quantity,
          startDate: startDate,
          totalAmount: totalPrice,
          monthlyAmount: monthlyPrice,
          savings: (basePrice * months * quantity) - totalPrice
        }
      }
    });
  };

  return (
    <div className="mt-52 bg-gradient-to-b from-gray-50 to-white min-h-[300vh]">
      {/* Fixed Navbar - 96px height (already exists in your app) */}
      
      {/* Progress Steps - Fixed below navbar */}
      <div className="fixed top-24 left-0 right-0 h-24 bg-white z-30 border-b shadow-sm">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto flex justify-center items-center px-4 h-full"
        >
          {steps.map(({ step, label, icon: Icon }) => (
            <React.Fragment key={step}>
              {step > 1 && (
                <div className={`w-20 h-1 rounded ${
                  step <= currentStep ? 'bg-green-500' : 'bg-gray-200'
                }`} />
              )}
              <motion.div
                whileHover={{ scale: 1.05 }}
                className={`flex flex-col items-center ${
                  step <= currentStep ? 'text-green-600' : 'text-gray-400'
                }`}
              >
                <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                  step <= currentStep 
                    ? 'bg-green-100 text-green-600' 
                    : 'bg-gray-100 text-gray-400'
                }`}>
                  <Icon className="w-5 h-5" />
                </div>
                <span className="text-sm mt-2">{label}</span>
              </motion.div>
            </React.Fragment>
          ))}
        </motion.div>
      </div>

      {/* Main Content Area - Adjusted for fixed elements */}
      <div className=""> {/* 96px (navbar) + 96px (progress) = 192px (48rem) */}
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  ">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Scrollable Selection Area */}
            <div className="lg:col-span-2 space-y-12 ">
              {/* Step 1: Dietary Preference Selection */}
              <motion.section
                id="step-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="space-y-6 pt-52"
              >
                <h2 className="text-2xl font-bold text-gray-900">Choose Your Dietary Preference</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {dietaryPreferences.map(pref => (
                    <PreferenceCard
                      key={pref.id}
                      preference={pref}
                      isSelected={dietaryPreference?.id === pref.id}
                      onSelect={(pref) => {
                        setDietaryPreference(pref);
                        setCurrentStep(2);
                      }}
                    />
                  ))}
                </div>
              </motion.section>

              {/* Only show plan selection after dietary preference is selected */}
              {dietaryPreference && (
                <>
                  {/* Step 2: Plan Selection */}
                  <motion.section
                    id="step-2"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-6 pt-52"
                  >
                    <h2 className="text-2xl font-bold text-gray-900">Choose Your Plan</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {filteredPlans.map(plan => (
                        <PlanCard
                          key={plan.id}
                          plan={plan}
                          isSelected={selectedPlan?.id === plan.id}
                          onSelect={(plan) => {
                            setSelectedPlan(plan);
                            setCurrentStep(3);
                          }}
                        />
                      ))}
                    </div>
                  </motion.section>

                  {/* Step 3: Duration Selection */}
                  {selectedPlan && (
                    <motion.section
                      id="step-3"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="space-y-6 pt-52"
                    >
                      <h2 className="text-2xl font-bold text-gray-900">Select Duration</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {durationOptions.map(option => (
                          <DurationCard
                            key={option.months}
                            option={option}
                            isSelected={selectedDuration?.months === option.months}
                            onSelect={(duration) => {
                              setSelectedDuration(duration);
                              setCurrentStep(4);
                            }}
                            basePrice={selectedPlan.price}
                          />
                        ))}
                      </div>
                    </motion.section>
                  )}

                  {/* Step 4: Quantity Selection */}
                  {selectedDuration && (
                    <motion.section
                      id="step-4"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="space-y-6 pt-52"
                    >
                      <h2 className="text-2xl font-bold text-gray-900">Number of People</h2>
                      <div className="bg-white p-8 rounded-2xl border border-gray-200">
                        <QuantitySelector
                          value={quantity}
                          onChange={(value) => {
                            setQuantity(value);
                            setCurrentStep(5);
                          }}
                        />
                      </div>
                    </motion.section>
                  )}

                  {/* Step 5: Date Selection */}
                  {quantity > 0 && (
                    <motion.section
                      id="step-5"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="space-y-6 pt-52"
                    >
                      <h2 className="text-2xl font-bold text-gray-900">Choose Start Date</h2>
                      <div className="bg-white p-8 rounded-2xl border border-gray-200">
                        <DatePicker
                          selectedDate={startDate}
                          onDateSelect={setStartDate}
                          minDate={minStartDate}
                        />
                      </div>
                    </motion.section>
                  )}
                </>
              )}
            </div>

            {/* Fixed Order Summary */}
            <div className="hidden lg:block">
              <div className="sticky top-48"> {/* 192px from top (navbar + progress) */}
              <BillingPanel
      selectedPlan={selectedPlan}
      selectedDuration={selectedDuration}
      quantity={quantity}
      startDate={startDate}
      className="mb-6"
      onProceedToPayment={handleProceedToPayment}
    />
                {/* ... existing order summary content ... */}
              </div>
            </div>

            {/* Mobile Order Summary */}
            <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg p-4 z-30">
            <BillingPanel
      selectedPlan={selectedPlan}
      selectedDuration={selectedDuration}
      quantity={quantity}
      startDate={startDate}
      className="mb-6"
      onProceedToPayment={handleProceedToPayment}
    />
              {/* ... existing mobile summary ... */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TiffinBooking;