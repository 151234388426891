import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OurKitchensPage from './pages/OurKitchensPage';
import OrderPage from './pages/OrderPage';
import SubscriptionPage from './pages/MenuPage';
import AboutPage from './pages/AboutPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Invoice from './components/Invoice';
import './App.css';
import TiffinPlans from './pages/TiffinPlans';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/OurKitchensPage" element={<OurKitchensPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/menu" element={<SubscriptionPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/tiffin-booking" element={<TiffinPlans />} />
            <Route path="/invoice" element={<Invoice />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;