import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { FaLeaf, FaUtensils, FaTruck, FaLaptop, FaCheckCircle, FaRocket, FaArrowRight, FaQuoteLeft, FaHeart, FaUsers, FaGlobeAsia, FaHandHoldingHeart, FaChevronUp } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { Link } from 'react-scroll';

const timelineData = [
  { year: 2014, title: "Humble Beginnings", icon: FaLeaf, description: "Sai Green started as a small garden restaurant, serving local favorites to the Bokaro community.", color: "#4CAF50" },
  { year: 2016, title: "Transition to Cloud Kitchen", icon: FaUtensils, description: "We pivoted to a cloud kitchen model, focusing on delivery and takeaway.", color: "#2196F3" },
  { year: 2018, title: "Expansion of Kitchens", icon: FaTruck, description: "We introduced specialized kitchens, each focusing on a unique cuisine, to cater to diverse tastes.", color: "#FFC107" },
  { year: 2020, title: "Business Pause & Research", icon: FaLaptop, description: "We paused operations to conduct extensive market research and refine our business model.", color: "#9C27B0" },
  { year: 2024, title: "Grand Relaunch", icon: FaRocket, description: "Sai Green is set to relaunch with cutting-edge technology and an enhanced menu.", color: "#F44336" }
];

const AnimatedCounter = ({ end, duration = 2.5 }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <span ref={ref}>
      {inView ? (
        <CountUp end={end} duration={duration} separator="," />
      ) : (
        '0'
      )}
    </span>
  );
};

const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <motion.div
      className={`fixed bottom-4 right-4 ${isVisible ? 'block' : 'hidden'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ duration: 0.3 }}
    >
      <Link to="top" smooth={true} duration={500}>
        <motion.button
          className="bg-green-500 text-white p-3 rounded-full shadow-lg"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaChevronUp />
        </motion.button>
      </Link>
    </motion.div>
  );
};

const AboutPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.2]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % timelineData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  
  const renderTimelineItem = useCallback(({ item, index }) => {
    return (
      <motion.div
        key={item.year}
        className={`mb-20 w-full md:w-1/2 ${
          index % 2 === 0 ? 'md:mr-auto md:pr-12' : 'md:ml-auto md:pl-12'
        }`}
        initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100, y: 50 }}
        whileInView={{ 
          opacity: 1, 
          x: 0,
          y: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.2
          }
        }}
        viewport={{ once: true, margin: "-100px" }}
      >
        <div className={`relative flex items-center ${index % 2 === 0 ? 'md:justify-end' : ''}`}>
          {/* Timeline connector line */}
          <motion.div 
            className={`absolute hidden md:block w-12 h-[2px] ${
              index % 2 === 0 ? '-right-12' : '-left-12'
            }`}
            style={{ backgroundColor: item.color }}
            initial={{ scaleX: 0 }}
            whileInView={{ scaleX: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          />

          {/* Icon container */}
          <motion.div 
            className="relative z-10"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div 
              className={`bg-white p-5 rounded-2xl shadow-[0_0_20px_rgba(0,0,0,0.1)] backdrop-blur-sm md:order-last 
                ${index % 2 === 0 ? 'md:ml-6' : 'md:mr-6'}`}
              style={{ backgroundColor: `${item.color}15` }}
              whileHover={{ 
                boxShadow: `0 0 30px ${item.color}40`,
                backgroundColor: `${item.color}25`
              }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                className="relative"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.8, type: "spring" }}
              >
                {React.createElement(item.icon, { 
                  className: "text-4xl",
                  style: { color: item.color }
                })}
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Content container */}
          <div className={`flex-1 ${index % 2 === 0 ? 'md:text-right' : 'md:text-left'}`}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              <span 
                className="inline-block px-3 py-1 text-sm font-semibold  rounded-full mb-2"
                style={{ 
                  backgroundColor: `${item.color}15`,
                  color: item.color
                }}
              >
                {item.year}
              </span>
              <h4 className="text-2xl font-bold text-black/70 mb-2">
                {item.title}
              </h4>
            </motion.div>
            
            <motion.p 
              className="text-gray-600 dark:text-black/70 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              {item.description}
            </motion.p>

            {/* Interactive elements */}
            <motion.div
              className={`mt-4 flex items-center gap-4 ${
                index % 2 === 0 ? 'md:justify-end' : ''
              }`}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <motion.button
                className="text-sm font-medium flex items-center gap-1 opacity-60 hover:opacity-100 transition-opacity"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ color: item.color }}
              >
                Learn more
                <FaArrowRight className="text-xs" />
              </motion.button>
            </motion.div>
          </div>
        </div>
      </motion.div>
    );
  }, []);

  const TimelineItems = useMemo(() => timelineData.map((item, index) => 
    renderTimelineItem({ item, index })
  ), [renderTimelineItem]);

  return (
    <ParallaxProvider>
      <div id="top" className="min-h-screen bg-gradient-to-br from-green-50 to-emerald-100 py-16 px-4 pt-44 sm:px-6 lg:px-8 overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="text-center mb-24"
          >
            <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 sm:text-7xl md:text-8xl mb-4">
              The Sai Green Journey
            </h1>
            <motion.p 
              className="mt-3 max-w-md mx-auto text-xl text-gray-600 sm:text-2xl md:mt-5 md:max-w-3xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              From garden to cloud: A story of flavor, innovation, and community.
            </motion.p>
            <motion.div
              className="mt-8 inline-block"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="story" smooth={true} duration={500} className="text-green-500 font-semibold text-lg flex items-center">
                Explore Our Story <FaArrowRight className="ml-2" />
              </Link>
            </motion.div>
          </motion.div>

          <div id="story" className="relative mb-32">
            <div className="absolute inset-0 flex items-center justify-center">
              <motion.div 
                className="w-1 h-full bg-gradient-to-b from-green-300 via-blue-300 to-purple-300 rounded-full"
                initial={{ height: 0 }}
                animate={{ height: '100%' }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
              ></motion.div>
            </div>
            <div className="relative flex flex-col items-center">
              {TimelineItems}
            </div>
          </div>

          <Parallax y={[-20, 20]} tagOuter="figure">
            <motion.div 
              style={{ scale }}
              className="bg-white rounded-lg shadow-2xl p-8 mb-24 transform hover:scale-105 transition duration-300"
            >
              <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-blue-500 mb-6">Our Mission</h2>
              <p className="text-xl text-gray-700 mb-8 leading-relaxed">
                At Sai Green, we're not just serving meals; we're crafting experiences, nurturing communities, and pioneering a sustainable future for food delivery. Our journey is guided by a simple yet powerful ethos: to bring joy, health, and connection to every plate we serve.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  { text: "Elevate everyday dining with exceptional flavors", icon: FaUtensils },
                  { text: "Champion food safety and hygiene standards", icon: FaCheckCircle },
                  { text: "Drive innovation in cloud kitchen technology", icon: FaRocket },
                  { text: "Foster sustainable, local food ecosystems", icon: FaLeaf },
                  { text: "Cultivate transparency and trust", icon: FaHeart },
                  { text: "Empower our community through good food", icon: FaUsers }
                ].map((item, index) => (
                  <motion.div 
                    key={index}
                    className="flex items-start"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <motion.div
                      whileHover={{ scale: 1.1, rotate: 360 }}
                      transition={{ duration: 0.3 }}
                    >
                      {React.createElement(item.icon, { className: "text-green-500 mt-1 mr-3 flex-shrink-0 text-2xl" })}
                    </motion.div>
                    <span className="text-gray-700 text-lg">{item.text}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </Parallax>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-blue-500 mb-12 text-center">The Sai Green Difference</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { title: "Farm-Fresh Ingredients", description: "We partner with local farmers to bring you the freshest, most flavorful ingredients in every dish.", icon: FaLeaf, color: "#4CAF50" },
                { title: "Culinary Artistry", description: "Our expert chefs blend traditional techniques with modern innovation to create unforgettable flavors.", icon: FaUtensils, color: "#FFC107" },
                { title: "Tech-Powered Experience", description: "From order to delivery, our cutting-edge technology ensures a seamless, personalized experience.", icon: FaLaptop, color: "#2196F3" },
                { title: "Sustainable Practices", description: "We're committed to eco-friendly packaging and zero-waste initiatives to protect our planet.", icon: FaGlobeAsia, color: "#009688" },
                { title: "Community First", description: "We believe in giving back. A portion of every order supports local food security initiatives.", icon: FaHandHoldingHeart, color: "#E91E63" },
                { title: "Transparency Promise", description: "Open kitchens, clear sourcing, and honest communication - we have nothing to hide.", icon: FaCheckCircle, color: "#9C27B0" },
              ].map((item, index) => (
                <motion.div 
                  key={index}
                  className="bg-white rounded-lg shadow-xl p-6 transform hover:scale-105 transition duration-300"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className="flex items-center mb-4">
                    <motion.div 
                      className="p-3 rounded-full mr-4" 
                      style={{ backgroundColor: item.color }}
                      whileHover={{ scale: 1.1, rotate: 360 }}
                      transition={{ duration: 0.3 }}
                    >
                      {React.createElement(item.icon, { className: "text-3xl text-white" })}
                    </motion.div>
                    <h3 className="text-xl font-semibold text-gray-800">{item.title}</h3>
                  </div>
                  <p className="text-gray-600 leading-relaxed">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <Parallax y={[-30, 30]} tagOuter="figure">
            <motion.div 
              className="bg-gradient-to-r from-green-400 to-blue-500 rounded-lg shadow-2xl p-12 mb-24 text-white"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold mb-8 text-center">Anticipating Tomorrow</h2>
              <p className="text-xl mb-12 text-center leading-relaxed">
                As we approach our grand relaunch in September 2024, Sai Green is poised to redefine the cloud kitchen experience. Our vision for the future is built on three transformative pillars:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {[
                  { icon: FaUtensils, title: "Gastronomic Innovation", description: "Embark on a culinary journey with our fusion of global cuisines and local flavors, crafted by world-class chefs and food scientists." },
                  { icon: FaRocket, title: "AI-Driven Personalization", description: "Experience dining tailored just for you, with our AI system learning your preferences to suggest perfect meal combinations." },
                  { icon: FaGlobeAsia, title: "Eco-Conscious Evolution", description: "Join us in our mission for sustainability, from biodegradable packaging to carbon-neutral delivery options." }
                ].map((item, index) => (
                  <motion.div 
                    key={index}
                    className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 flex flex-col items-center text-center"
                    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <motion.div
                      whileHover={{ rotate: 360 }}
                      transition={{ duration: 0.5 }}
                    >
                      {React.createElement(item.icon, { className: "text-5xl mb-4" })}
                    </motion.div>
                    <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                    <p>{item.description}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </Parallax>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-24"
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <FaQuoteLeft className="text-6xl text-green-500 mx-auto mb-6" />
            </motion.div>
            <motion.blockquote 
              className="text-2xl font-semibold text-gray-800 mb-8 px-4 sm:px-8 md:px-16 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              "Every meal tells a story - of tradition, of innovation, of care. At Sai Green, we're not just delivering food; we're delivering experiences, memories, and a taste of home. As we embark on this new chapter, we invite you to be part of our story, to create new flavors and memories together."
            </motion.blockquote>
            <motion.p 
              className="text-xl text-green-600"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              - Shikha Prasad, Founder & CEO, Sai Green
            </motion.p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="mb-24"
          >
            <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-blue-500 mb-12 text-center">
              Our Impact in Numbers
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                { value: 1000000, label: "Meals Served", icon: FaUtensils },
                { value: 50000, label: "Happy Customers", icon: FaUsers },
                { value: 100, label: "Local Farmers Supported", icon: FaLeaf },
                { value: 5000, label: "Trees Planted", icon: FaGlobeAsia },
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-white rounded-lg shadow-xl p-6 text-center"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <motion.div
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    {React.createElement(stat.icon, { className: "text-4xl text-green-500 mb-4 mx-auto" })}
                  </motion.div>
                  <div className="text-3xl font-bold text-gray-800 mb-2">
                    <AnimatedCounter end={stat.value} />+
                  </div>
                  <p className="text-gray-600">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <Parallax y={[-20, 20]} tagOuter="figure">
            <motion.div 
              className="bg-white rounded-lg shadow-2xl p-8 mb-24"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-blue-500 mb-8 text-center">
                Join the Sai Green Family
              </h2>
              <p className="text-xl text-gray-700 mb-8 text-center leading-relaxed">
                Be part of our journey as we redefine the cloud kitchen experience. Sign up for our exclusive waitlist and enjoy:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                {[
                  { text: "Early access to our new menu", icon: FaUtensils },
                  { text: "Special launch day discounts", icon: FaTruck },
                  { text: "Invitation to our virtual tasting event", icon: FaLaptop },
                ].map((item, index) => (
                  <motion.div
                    key={index}
                    className="flex items-center bg-green-50 rounded-lg p-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <motion.div
                      whileHover={{ rotate: 360 }}
                      transition={{ duration: 0.5 }}
                    >
                      {React.createElement(item.icon, { className: "text-green-500 text-2xl mr-4 flex-shrink-0" })}
                    </motion.div>
                    <span className="text-gray-800">{item.text}</span>
                  </motion.div>
                ))}
              </div>
              <form className="max-w-md mx-auto">
                <div className="flex items-center border-b border-green-500 py-2">
                  <input 
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
                    type="email" 
                    placeholder="Enter your email" 
                    aria-label="Email"
                  />
                  <motion.button 
                    className="flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded" 
                    type="button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Join Waitlist
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </Parallax>

          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-8">
              Ready to Experience the Future of Food?
            </h2>
            <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
              From our kitchen to your table, join us in revolutionizing the way Bokaro eats. Be the first to savor our new flavors, experience our tech-enhanced service, and be part of a community that cares about great food and a better future.
            </p>
            <motion.button
              className="bg-gradient-to-r from-green-400 to-blue-500 text-white px-10 py-4 rounded-full text-xl font-semibold shadow-lg hover:shadow-xl transition duration-300 flex items-center mx-auto"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Notified at Launch <FaArrowRight className="ml-3" />
            </motion.button>
          </motion.div>
        </div>
      </div>
      <FloatingActionButton />
    </ParallaxProvider>
  );
};

export default React.memo(AboutPage);