import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { SubscriptionPlans } from '../components/SubscriptionPlans';
import { FaCheck, FaUtensils, FaClock, FaCalendarAlt, FaLeaf, FaAppleAlt, FaCarrot, FaCookie, FaBrain, FaChartBar, FaExchangeAlt, FaLock, FaUser, FaCreditCard, FaShoppingCart, FaQuestion, FaMoon, FaSun, FaHeart, FaCoffee } from 'react-icons/fa';
import heroMenuImage from '../images/ai-generated-rice-with-chicken-and-vegetables-plate-of-indian-curry-on-transparent-background-image-png.webp';

// Updated plans array with the new structure from the menu
const plans = [
  {
    name: 'Nashta',
    price: '₹1,199',
    actualPrice: '₹1,599',
    duration: 'per week',
    meals: '7 breakfast/week',
    description: 'Start your day right with our delicious breakfast options',
    features: [
      'Fresh morning meals',
      'Different options each day',
      'Free delivery',
      'Flexible timing'
    ],
    recommendedFor: 'Early birds, Office goers',
    menu: {
      mon: 'Puri Bhaji (4pc), Mint Chutney',
      tue: 'Aloo/Dal/Sattu Puri (3pc)',
      wed: 'Paratha Bhujiya (2pc), Curd',
      thu: 'Bread Sandwich',
      fri: 'Roti Bhujiya (3pc), Pickle',
      sat: 'Sattu/Aloo Paratha (2pc), Chutney',
      sun: 'Chowmin/Idli (3pc), Sauce/Sambhar'
    },
    mealsPerWeek: 7
  },
  {
    name: 'Basic',
    price: '₹1,700',
    actualPrice: '₹2,299',
    duration: 'per week',
    meals: '7 meals/week',
    description: 'Perfect for single meal requirements',
    features: [
      'Choice of lunch or dinner',
      'Balanced meals',
      'Free delivery',
      '24/7 customer support'
    ],
    recommendedFor: 'Students, Single professionals',
    menu: {
      mon: 'Rice, Dal, Aloo Gobhi, Pickle, 1pc Rasgulla',
      tue: 'Rice, Dal/Kadhi, Aloo Patal, Pyaaj Pakoda, 1pc Gulab Jamun',
      wed: 'Rice, Dal, Egg/Chicken Curry, Salad, Kheer',
      thu: 'Veg Biriyani, Raita, Aachar, 1pc Chamcham',
      fri: 'Rice, Dal, Matar Paneer/Fish/Chicken, 1pc Rasgulla',
      sat: 'Rice, Seasonal Green Veg/Aloo Chana, Aachar, 1pc Gulab Jamun',
      sun: 'Jeera Rice, Matar Paneer/Chicken, Dal, Special Mithai'
    },
    mealsPerWeek: 7
  },
  {
    name: 'Standard',
    price: '₹3,299',
    actualPrice: '₹3,999',
    duration: 'per week',
    meals: '14 meals/week',
    description: 'Ideal for lunch and dinner coverage',
    features: [
      'Both lunch and dinner',
      'Premium menu options',
      'Priority delivery',
      'Customize your menu',
      'Weekend special meals'
    ],
    recommendedFor: 'Working professionals, Couples',
    menu: {
      lunch: {
        mon: 'Rice, Dal, Aloo Gobhi, Pickle, 1pc Rasgulla',
        tue: 'Rice, Dal/Kadhi, Aloo Patal, Pyaaj Pakoda, 1pc Gulab Jamun',
        wed: 'Rice, Dal, Egg/Chicken Curry, Salad, Kheer',
        thu: 'Veg Biriyani, Raita, Aachar, 1pc Chamcham',
        fri: 'Rice, Dal, Matar Paneer/Fish/Chicken, 1pc Rasgulla',
        sat: 'Rice, Seasonal Green Veg/Aloo Chana, Aachar, 1pc Gulab Jamun',
        sun: 'Jeera Rice, Matar Paneer/Chicken, Dal, Special Mithai'
      },
      dinner: {
        mon: 'Puri (4pc), Chola, Aachar',
        tue: 'Aloo/Sattu Paratha (4pc), Pickles',
        wed: 'Roti (6pc), Seasonal Green Veg',
        thu: 'Puri (6pc), Aloo Dum',
        fri: 'Roti (6pc), Mix Veg, Aloo Bhujiya',
        sat: 'Munchurian/Soya/Paneer Chilli',
        sun: 'Roti (6pc), Chola, Sawai'
      }
    },
    mealsPerWeek: 14
  },
  {
    name: 'Premium',
    price: '₹4,099',
    actualPrice: '₹5,499',
    duration: 'per week',
    meals: '21 meals/week',
    description: 'Complete coverage for all your meals',
    features: [
      'Breakfast, lunch and dinner',
      'Premium menu selection',
      'Priority delivery',
      'Personalized menu',
      'Weekly chef specials',
      'Nutritionist consultation',
      'Premium customer support'
    ],
    recommendedFor: 'Families, Health enthusiasts',
    menu: {
      breakfast: {
        mon: 'Puri Bhaji (4pc), Mint Chutney',
        tue: 'Aloo/Dal/Sattu Puri (3pc)',
        wed: 'Paratha Bhujiya (2pc), Curd',
        thu: 'Bread Sandwich',
        fri: 'Roti Bhujiya (3pc), Pickle',
        sat: 'Sattu/Aloo Paratha (2pc), Chutney',
        sun: 'Chowmin/Idli (3pc), Sauce/Sambhar'
      },
      lunch: {
        mon: 'Rice, Dal, Aloo Gobhi, Pickle, 1pc Rasgulla',
        tue: 'Rice, Dal/Kadhi, Aloo Patal, Pyaaj Pakoda, 1pc Gulab Jamun',
        wed: 'Rice, Dal, Egg/Chicken Curry, Salad, Kheer',
        thu: 'Veg Biriyani, Raita, Aachar, 1pc Chamcham',
        fri: 'Rice, Dal, Matar Paneer/Fish/Chicken, 1pc Rasgulla',
        sat: 'Rice, Seasonal Green Veg/Aloo Chana, Aachar, 1pc Gulab Jamun',
        sun: 'Jeera Rice, Matar Paneer/Chicken, Dal, Special Mithai'
      },
      dinner: {
        mon: 'Puri (4pc), Chola, Aachar',
        tue: 'Aloo/Sattu Paratha (4pc), Pickles',
        wed: 'Roti (6pc), Seasonal Green Veg',
        thu: 'Puri (6pc), Aloo Dum',
        fri: 'Roti (6pc), Mix Veg, Aloo Bhujiya',
        sat: 'Munchurian/Soya/Paneer Chilli',
        sun: 'Roti (6pc), Chola, Sawai'
      }
    },
    mealsPerWeek: 21
  }
];

const SubscriptionPage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [showAIQuestionnaire, setShowAIQuestionnaire] = useState(false);
  const [selectedMealTime, setSelectedMealTime] = useState('All');

  // 1. Memoize the Hero component props
  const heroProps = useMemo(() => ({
    darkMode,
    setShowAIQuestionnaire,
    heroMenuImage
  }), [darkMode]);

  // 2. Extract Hero into a separate component and memoize it
  const Hero = React.memo(({ darkMode, setShowAIQuestionnaire, heroMenuImage }) => (
    <div className={`bg-gradient-to-r ${darkMode ? 'from-purple-800 to-indigo-900' : 'from-green-200 to-emerald-200'} text-black/70 px-4 sm:px-6 lg:px-12 relative overflow-hidden mx-auto pt-24 pb-36`}>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto relative z-10 p-4"
      >
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4 bg-clip-text ">Ghar Jaisa Khana, Ghar Tak!</h1>
        <p className="text-lg md:text-xl mb-8 opacity-90">Experience the perfect blend of home-cooked goodness and modern convenience.</p>
        <div className="flex flex-wrap gap-4">
          <Link to="/tiffin-booking">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-black/70 text-green-300'} px-8 py-3 rounded-xl text-lg font-semibold  shadow-2xl `}
          >
            Start Your Booking
          </motion.button> 
          </Link>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => document.getElementById('weekly-menu').scrollIntoView({ behavior: 'smooth' })}
            className="bg-transparent border-2 bg-blend-color-dodge border-black/60 px-8 py-3 rounded-xl text-lg font-semibold  shadow-xl"
          >
            View Weekly Menu
          </motion.button>
        </div>
      </motion.div>
      
      <div className={`absolute bottom-0 left-0 w-full h-32 
        ${darkMode ? 
          'bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent' : 
          'bg-gradient-to-t from-gray-50 via-gray-50/80 to-transparent'
        }`}
      />
      
      <div className="absolute opacity-20"></div>
      <img 
        src={heroMenuImage} 
        alt="Hero Image" 
        className="w-80 h-80 object-cover opacity-100 absolute md:block hidden top-16 right-48" 
        style={{ filter: 'drop-shadow(0 10px 10px rgba(0, 0, 0, 0.4))' }} 
      />
    </div>
  ));

  // 3. Memoize the WeeklyMenu component props
  const weeklyMenuProps = useMemo(() => ({
    selectedMealTime,
    setSelectedMealTime,
    darkMode
  }), [selectedMealTime, darkMode]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  // Extract WeeklyMenu into a separate component that only re-renders when selectedMealTime changes
  const WeeklyMenu = React.memo(({ selectedMealTime, setSelectedMealTime, darkMode }) => (
    <motion.div 
      id="weekly-menu"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
      className={`max-w-7xl mx-auto px-6 py-16 ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}
    >
      {/* Enhanced Header Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h2 className={`text-4xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
          Weekly Menu
          <span className="inline-block ml-2">
            
          </span>
        </h2>
        <p className={`text-lg ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Explore our delicious weekly offerings, crafted with love and care
        </p>
      </motion.div>

      {/* Enhanced Menu Type Selector */}
      <div className="flex justify-center gap-3 mb-12">
        {['All', 'Nashta', 'Lunch', 'Dinner'].map((mealType) => (
          <motion.button
            key={mealType}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setSelectedMealTime(mealType)}
            className={`
              px-3 sm:px-6 py-2 sm:py-3 rounded-xl font-medium transition-all duration-300
              ${selectedMealTime === mealType
                ? darkMode
                  ? 'bg-purple-600 text-white shadow-lg shadow-purple-500/30'
                  : 'bg-emerald-500 text-white shadow-lg shadow-emerald-500/30'
                : darkMode
                  ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }
              flex items-center gap-2
            `}
          >
            {mealType === 'Nashta' && <FaCoffee className="text-sm" />}
            {mealType === 'Lunch' && <FaUtensils className="text-sm" />}
            {mealType === 'Dinner' && <FaMoon className="text-sm" />}
            {mealType === 'All' && <FaCalendarAlt className="text-sm" />}
            <span className="capitalize">{mealType}</span>
          </motion.button>
        ))}
      </div>

      {/* Enhanced Menu Grid */}
      <div className="grid grid-cols-1 md:grid-cols-7 gap-6">
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
          <motion.div
            key={day}
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: { delay: index * 0.1 }
              }
            }}
            whileHover={{ y: -5 }}
            className={`
              ${darkMode ? 'bg-gray-800' : 'bg-white'}
              rounded-2xl p-5 shadow-lg
              ${darkMode ? 'shadow-gray-900/50' : 'shadow-gray-200/50'}
              transform transition-all duration-300
              hover:shadow-xl
            `}
          >
            {/* Day Header */}
            <div className="flex flex-col items-left justify-between mb-4">
              <span className={`text-xs  ${
                darkMode ? 'bg-gray-700 text-gray-300' : ' text-gray-600'
              }`}>
                Day {index + 1}
              </span>
              <h3 className={`font-bold text-xl ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                {day}
              </h3>
            </div>

            {/* Meal Sections */}
            {(selectedMealTime === 'All' || selectedMealTime === 'Nashta') && (
              <div className="mb-4 space-y-2">
                <h4 className="text-emerald-500 decoration-solid font-semibold flex items-center gap-2">
                  <FaCoffee className="text-sm" />
                  <a className="decoration-emerald-500/[.33] underline">Nashta</a>
                </h4>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {plans[3].menu.breakfast[`mon`.toLowerCase()]}
                </p>
              </div>
            )}

            {(selectedMealTime === 'All' || selectedMealTime === 'Lunch') && (
              <div className="mb-4 space-y-2">
                <h4 className="text-blue-500 font-semibold flex items-center gap-2">
                  <FaUtensils className="text-sm" />
                  <a className="decoration-blue-500/[.33] underline">Lunch</a>
                </h4>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {plans[3].menu.lunch[`mon`.toLowerCase()]}
                </p>
              </div>
            )}

            {(selectedMealTime === 'All' || selectedMealTime === 'Dinner') && (
              <div className="space-y-2">
                <h4 className="text-purple-500 font-semibold flex items-center gap-2">
                  <FaMoon className="text-sm" />
                  <a className="decoration-purple-500/[.33] underline">Dinner</a>
                </h4>
                <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {plans[3].menu.dinner[`mon`.toLowerCase()]}
                </p>
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </motion.div>
  ));

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50'}`}>
      <main className="pt-16">
        <Hero {...heroProps} />
        <WeeklyMenu {...weeklyMenuProps} />
        <SubscriptionPlans />
      </main>
    </div>
  );
};

export default SubscriptionPage;