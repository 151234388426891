import React from 'react';
import { motion } from 'framer-motion';
import indianPlate from '../images/indian-plate.png';
import ingredients from '../images/ingredient.png';
import service from '../images/customer-service.png';
import nutritionist from '../images/nutrition-plan.png';
import easyOrder from '../images/order.png';
import fssai from '../images/guarantee.png';

import { FaArrowRight, FaLeaf, FaClock, FaTruck, FaUtensils,FaHeart  } from 'react-icons/fa';

const EnhancedHero = () => {
  return (
    <div className="relative min-h-screen bg-gradient-to-br from-green-50 to-emerald-50 overflow-hidden">
      {/* Animated Background Elements */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 pointer-events-none"
        style={{
          backgroundImage: `radial-gradient(circle at 2px 2px, rgba(0,0,0,0.1) 1px, transparent 0)`,
          backgroundSize: '48px 48px'
        }}
      />

      {/* Main Content Container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          
          {/* Left Column - Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            {/* Floating Badge */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center px-2 py-1 rounded-full bg-white shadow-lg mb-8"
            >
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 4, repeat: Infinity, ease: "linear" }}
                className="w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-2"
              >
                <FaLeaf className="text-green-500 text-sm" />
              </motion.div>
              <span className="text-green-800 text-xs font-extralight">
              Ghar Ka Khana, Expert Ka Promise
              </span>
            </motion.div>

            {/* Main Heading */}
            <motion.h1 
              className="text-5xl md:text-7xl font-extrabold mb-8 leading-tight"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-emerald-600">
              Healthy Tiffin
              </span>
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-emerald-600 to-green-600">
              Happy Living
              </span>
              {/* <div className="text-2xl mt-4 text-gray-700 font-medium">
                FSSAI Certified Kitchen • Diet-Specific Meals • Expert Nutritionists
              </div> */}
            </motion.h1>

            {/* Subheading */}
            <motion.p 
              className="text-lg font-extralight text-gray-600 mb-10 max-w-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              Your journey to healthier living starts with every meal. We blend traditional recipes with modern nutritional science, delivering perfectly portioned, wholesome meals tailored to your wellness goals.
            </motion.p>

            {/* CTA Buttons */}
            <motion.div 
              className="flex flex-wrap gap-4 mb-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-gradient-to-r from-green-500 to-emerald-600 
                  text-white rounded-xl font-semibold text-lg shadow-xl hover:shadow-2xl 
                  transition-all duration-300 flex items-center space-x-2"
              >
                <span>Order Now</span>
                <motion.div
                  animate={{ x: [0, 5, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                >
                  <FaArrowRight />
                </motion.div>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-white text-green-600 rounded-xl font-semibold 
                  text-lg shadow-lg hover:shadow-xl transition-all duration-300"
              >
                View Menu
              </motion.button>
            </motion.div>

            {/* Stats */}
            <div className="grid grid-cols-3 gap-6">
              {[
                { 
                  icon: FaLeaf, 
                  value: "5000+", 
                  label: "Happy Customers",
                  description: "Trust our nutrition"
                },
                { 
                  icon: FaUtensils, 
                  value: "12+", 
                  label: "Years of Excellence",
                  description: "In serving nutritious meals"
                },
                { 
                  icon: FaHeart, 
                  value: "100%", 
                  label: "Natural Ingredients",
                  description: "Fresh and locally sourced"
                }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 + (index * 0.1) }}
                  className="text-center"
                >
                  <motion.div
                    whileHover={{ scale: 1.1, rotate: 360 }}
                    transition={{ duration: 0.3 }}
                    className="w-12 h-12 rounded-full bg-white shadow-lg flex items-center 
                      justify-center mx-auto mb-3"
                  >
                    <stat.icon className="text-green-500 text-xl" />
                  </motion.div>
                  <div className="font-bold text-2xl text-gray-800">{stat.value}</div>
                  <div className="font-medium text-green-600">{stat.label}</div>
                  <div className="text-sm text-gray-500 mt-1">{stat.description}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Column - Image and Floating Elements */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            {/* Main Image */}
            <motion.div
              animate={{ y: [-10, 10, -10] }}
              transition={{ duration: 4, repeat: Infinity, repeatType: "reverse" }}
              className="relative z-10"
            >
              <img
                src={indianPlate}
                alt="Delicious Food"
                // className="rounded-3xl shadow-2xl"
              />
              
              {/* Floating Cards */}
              {/* Achievement Badges */}
              {/* <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="absolute -right-20 -top-5 bg-white rounded-xl shadow-xl p-4 w-64"
              >
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                    <FaLeaf className="text-green-500" />
                  </div>
                  <div>
                    <div>
                    <div className="font-semibold text-gray-800">FSSAI Certified Kitchen</div>
                    <div className="mt-2 flex gap-2">
                      <span className="px-2 py-1 bg-green-50 text-green-600 rounded-full text-xs font-medium">Verified</span>
                      <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-xs font-medium">ISO 22000:2018</span>
                    </div>
                  </div>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="absolute -left-28 bottom-3.5 bg-white rounded-xl shadow-xl p-4 w-56"
               >
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                    <FaLeaf className="text-green-500" />
                  </div>
                  <div>
                    <div className="font-semibold text-gray-800">Fresh & Healthy</div>
                    <div className="text-sm text-gray-500">100% Organic</div>
                  </div>
                </div>
              </motion.div> */}
            </motion.div>

            {/* Background Decorative Elements */}
            <motion.div
              animate={{ 
                rotate: 360,
                scale: [1, 1.1, 1]
              }}
              transition={{ duration: 20, repeat: Infinity }}
              className="absolute top-0 right-0 w-72 h-72 bg-gradient-to-r 
                from-green-300/20 to-emerald-300/20 rounded-full blur-3xl"
            />
            <motion.div
              animate={{ 
                rotate: -360,
                scale: [1, 1.2, 1]
              }}
              transition={{ duration: 25, repeat: Infinity }}
              className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-r 
                from-emerald-300/20 to-green-300/20 rounded-full blur-3xl"
            />
          </motion.div>
        </div>
      </div>

      {/* Services Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl md:text-4xl font-bold text-center mb-16"
        >
          Our Services
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {[
            { title: "Best Quality Ingredients", icon: ingredients },
            { title: "Amazing Customer Service", icon: service },
            { title: "InHouse Nutritionist", icon: nutritionist },
            { title: "Easy Ordering & Cancellation Policy", icon: easyOrder },
            { title: "Fssai Certified Kitchen", icon: fssai }
          ].map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * index }}
              className="text-center"
            >
              <motion.div
                whileHover={{ scale: 1.1, rotate: 10 }}
                className="w-24 h-24 rounded-full bg-white shadow-lg mx-auto mb-4 
                  flex items-center justify-center"
              >
                <img src={service.icon} alt={service.title} className="w-12 h-12" />
              </motion.div>
              <h3 className="font-medium text-sm text-gray-800">{service.title}</h3>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnhancedHero;